import React, { useEffect } from 'react';
import './Fonts.css';
import './App.css';
import Home from './components/pages/Home';
import Project from './components/pages/Project';
import useScrollToTop from './components/hooks/ScrollToTop';
import { HashRouter, Routes, Route } from 'react-router-dom'; // Import HashRouter
import { LanguageProvider } from './language/LanguageProvider';
import { MediaProvider, useMedia } from './components/context/MediaContext'; // Correct import
import Loader from './components/reusableComponents/Loader'; // Import the Loader component

function App() {
    return (
        <MediaProvider>
            <AppContent />
        </MediaProvider>
    );
}

function AppContent() {
    const { homeMediaLoaded, setHomeMediaLoaded, setProjectMediaLoaded } = useMedia();

    useEffect(() => {
        const fetchHomeData = async () => {
            try {
                const globalImages = importAll(require.context('./assets/img/home', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
                // const globalVideos = importAll(require.context('./assets/video/home', false, /\.(mp4|webm|ogg)$/));
                // console.log('Imported home images:', globalImages); // Debugging line
                // console.log('Imported home videos:', globalVideos); // Debugging line
                await cacheMedia(globalImages, 'image');
                // await cacheMedia(globalVideos, 'video');
                setHomeMediaLoaded(true);
            } catch (error) {
                console.error('Error loading home media:', error);
            }
        };

        const fetchProjectData = async () => {
            try {
                const globalImages = importAll(require.context('./assets/img/projects', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
                const globalVideos = importAll(require.context('./assets/video/projects', false, /\.(mp4|webm|ogg)$/));
                // console.log('Imported project images:', globalImages); // Debugging line
                // console.log('Imported project videos:', globalVideos); // Debugging line
                await cacheMedia(globalImages, 'image');
                await cacheMedia(globalVideos, 'video');
                setProjectMediaLoaded(true);
            } catch (error) {
                console.error('Error loading project media:', error);
            }
        };

        fetchHomeData();
        fetchProjectData();
    }, [setHomeMediaLoaded, setProjectMediaLoaded]);

    function importAll(r) {
        return r.keys().map(r);
    }

    const cacheMedia = async (srcArray, type) => {
        const promises = srcArray.map((src) => {
            return new Promise((resolve, reject) => {
                let media;
                if (type === 'image') {
                    media = new Image();
                    media.src = src.default || src; // Ensure src is correctly set
                    media.onload = () => resolve(true);
                    media.onerror = () => reject(new Error(`Failed to load ${type}: ${media.src}`));
                } else if (type === 'video') {
                    media = document.createElement('video');
                    media.src = src.default || src; // Ensure src is correctly set
                    media.onloadeddata = () => resolve(true);
                    media.onerror = () => reject(new Error(`Failed to load ${type}: ${media.src}`));
                }
            });
        });

        await Promise.all(promises);
    };

    return (
        <LanguageProvider>
            <HashRouter>
                <ScrollToTopWrapper>
                    {homeMediaLoaded ? (
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/project/:id" element={<Project />} />
                        </Routes>
                    ) : (
                      <Loader /> 
                    )}
                </ScrollToTopWrapper>
            </HashRouter>
        </LanguageProvider>
    );
}

function ScrollToTopWrapper({ children }) {
    useScrollToTop();
    return children;
}

export default App;