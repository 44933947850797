import React, {useContext} from 'react';
import { LanguageContext } from '../../language/LanguageContext.js';
import ArrowButton from '../reusableComponents/ArrowButton.js';
import './Header.scss';

const Header = () => {
    const { dictionary } = useContext(LanguageContext);

    return (
        <div className="header">
            <div className='info-wrapper'>
                <h1>Tobias Henning</h1>
                <p>{dictionary.header.title}</p>
            </div>
            <ArrowButton direction='bottom' onClick={() => document.querySelector('.cv').scrollIntoView({ behavior: 'smooth' })} />
        </div>
    );
};

export default Header;