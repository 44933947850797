import React, { useContext, useEffect } from 'react';
import './Navigation.scss';
import { LanguageContext } from '../../language/LanguageContext';
import { throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ColorHelper from '../helpers/ColorHelper';

const Navigation = ({type="home"}) => {
    const { dictionary } = useContext(LanguageContext);
    const entries = dictionary.navigation;
    const navigate = useNavigate();

    const scrollToElement = (id) => {

        const getElement = (id) => {
            switch (id) {
                case 'home':
                    return document.querySelector('.parallax-container');
                case 'cv':
                    return document.querySelector('.cv');
                case 'projects':
                    return document.querySelector('.projects');
                case 'contact':
                    return document.querySelector('.contact');
                default:
                    return null;
            }
        };
    
        const element = getElement(id);
        if (!element) return;
    
        const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        const duration = 1000; // Duration in milliseconds
        let start = null;
    
        const easeInOutQuad = (t) => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };
    
        const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const progressPercentage = Math.min(progress / duration, 1);
            const ease = easeInOutQuad(progressPercentage);
            window.scrollTo(0, startPosition + distance * ease);
            if (progress < duration) {
                window.requestAnimationFrame(step);
            }
        };
    
        window.requestAnimationFrame(step);
    };

    useEffect(() => {
        if(type !== "home")
            return;

        let lastRun = false;
        const handleScroll = throttle(() => {
            const cvElement = document.querySelector('.cv');
            if (cvElement) {
                const rect = cvElement.getBoundingClientRect();
                let height = window.scrollY + rect.top;
                let opacity = window.scrollY / height;
                if (opacity > 1) {
                    opacity = 1;
                    if(lastRun){
                        return;
                    }
                    lastRun = true;
                }else{
                    lastRun = false;
                }
                document.querySelector('.navigation .background').style.opacity = opacity;
                document.querySelector('.header .info-wrapper').style.setProperty('opacity', 1 - opacity * 2, 'important');
                
                const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
                if (!darkModeMediaQuery.matches) {
                    //remove color style
                    document.querySelector('.navigation').style.color = '';
                    document.querySelectorAll('.navigation button').forEach(button => {
                        button.style.color = '';
                    });
                    return;
                }
                const rootStyles = getComputedStyle(document.documentElement);
                const textColor = rootStyles.getPropertyValue('--primary-text-color').trim();
                const invertedTextColor = rootStyles.getPropertyValue('--inverted-primary-text-color').trim();

                const specialColor = rootStyles.getPropertyValue('--special-color').trim();

                

                const interpolatedColor = ColorHelper.interpolateColor(invertedTextColor,textColor, opacity);
                const interpolatedBorderColor = ColorHelper.opacityColor(specialColor, opacity);

                document.querySelector('.navigation').style.color = interpolatedColor;
                document.querySelector('.navigation').style.borderColor = interpolatedBorderColor;

                document.querySelectorAll('.navigation button').forEach(button => {
                    button.style.color = interpolatedColor;
                });
                document.querySelector('.language-selector').style.color = interpolatedColor;
                document.querySelectorAll('.language-selector button').forEach(button => {
                    button.style.color = interpolatedColor;
                });

            }
        }, 10); // Adjust the throttle delay as needed

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        
    }, [type]);
    console.log(type, type === "project");
    
    const handleHomeClick = () => {
        navigate('/');
    };

        

    return (
        <div className={`navigation ${type}-type`}>
            <div className={'background'}></div>
            {type === 'home' ? (
                Object.keys(entries).map((key, index) => (
                    <React.Fragment key={key}>
                        <button className={'navigation-button fancy-link'} onClick={() => scrollToElement(key)}>
                            {entries[key]}
                        </button>
                        {index !== Object.keys(entries).length - 1 && <span>|</span>}
                    </React.Fragment>
                ))
            ) : type === 'project' ? (
                <button className={'navigation-button fancy-link'} onClick={handleHomeClick}>
                    {entries.home}
                </button>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Navigation;