import React, {  useContext } from 'react';
import './Projects.scss';
import { LanguageContext } from '../../language/LanguageContext';
import { Link } from 'react-router-dom';

const Projects = () => {
    const { dictionary } = useContext(LanguageContext);

    // useEffect(() => {
    //     const handleScroll = throttle(() => {
    //         const viewportHeight = window.innerHeight;
    //         const element = document.querySelector('.projects .projects-wrapper');
    //         const rect = element.getBoundingClientRect();
    //         const isVisible = rect.top <= viewportHeight;
    //         if (isVisible) {
    //             if (!element.classList.contains('visible')) {
    //                 element.classList.add('visible');
    //             }
    //         } else {
    //             if (element.classList.contains('visible')) {
    //                 element.classList.remove('visible');
    //             }
    //         }
    //     }, 200); // Adjust the throttle delay as needed

    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    return (
        <>
            <div className='projects'>
                <h2>{dictionary.navigation.projects}</h2>
                <div className='projects-wrapper visible'>
                    {dictionary.projects.elements.map((project, index) => (
                        <div key={index} className="card">
                            <img src={require(`../../assets/img/${project.cardImage}`)} alt="" />
                            <div className="card-background"></div>
                            <div className="card-content">
                                <h2>{project.title}</h2>
                                <p>{project.short}</p>
                                <Link className="fancy-link" to={`/project/${project.id}`}>Find out more &rarr;</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Projects;